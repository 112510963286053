// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js

const myMSALObj = new msal.PublicClientApplication(msalConfig);
let username = "";

function selectAccount() {
    const currentAccounts = myMSALObj.getAllAccounts();
    if (currentAccounts.length === 0) {
        return;
    } else if (currentAccounts.length > 1) {
        // Add choose account code here
        console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
        username = currentAccounts[0].username;
        showWelcomeMessage(currentAccounts[0]);
        return currentAccounts[0]
    }
}
function showWelcomeMessage(account) {
    // renderAuthBanner && renderAuthBanner()
    // $('#authBanner').removeClass('authBanner').html('')
    // $('#user-dropdown').css("display", "block");
}

function registerUser(user) {
    const endpoint = apiUrl + "/patient/createPatientProfile/";
    const token = sessionStorage.getItem("token");
    return $.ajax({
        url: endpoint,
        headers: {
            Authorization: `Bearer ${token}`
        },
        method: "POST",
        data: user,
        timeout: 200000,
    })
}

function updateScreenerAllPiiAndGenericHealthQuestionAnswers({ patientId, patientProfileId }) {
    const endpoint = apiUrl + "/patient/updateScreenerAllPiiAndGenericHealthQuestionAnswers/";
    const token = sessionStorage.getItem("token");
    return $.ajax({
        url: endpoint,
        headers: {
            Authorization: `Bearer ${token}`
        },
        method: "POST",
        data: { patientId, patientProfileId },
        timeout: 200000,
    })
}
const getSocialAccountType = (idpUrl) => {
    switch (idpUrl) {
        case "google.com":
            return "google"

        case "facebook.com":
            return "facebook"

        case "apple.com":
            return "apple"

        default:
            return "email"
    }
}

function registerResponse(response) {
    if (response !== null) {
        const patientId = currentPatientId
        let registerPayload = {
            patientId,
            azureId: response.uniqueId,
            emailId: response.account.username,
            socialAccountType: "email",
            socialAccountUserName: response.account.username,
        }
        if (response.account.idTokenClaims && response.account.idTokenClaims.idp) {
            registerPayload.socialAccountType = getSocialAccountType(response.account.idTokenClaims.idp)
        }
        // User Register Call API
        registerUser(registerPayload).then((res => {
            if (signUpAllowed && res.apiResponse.patientProfileId) {
                updateScreenerAllPiiAndGenericHealthQuestionAnswers({ patientId, patientProfileId: res.apiResponse.patientProfileId }).then(() => {
                    userEmail = response.username
                    sessionStorage.setItem('userEmail', response.username)
                    renderAuthBanner()
                    username = response.account.username;
                    showWelcomeMessage(response.account);
                    // location.reload();
                })
            } else {
                userEmail = response.username
                sessionStorage.setItem('userEmail', response.username)
                renderAuthBanner()
                username = response.account.username;
                showWelcomeMessage(response.account);
                // location.reload();
            }
        })).catch(err => {
            console.log({ err })
        })
    } else {
        selectAccount();
    }
}
function loginResponse(response) {
    if (response !== null) {
        const patientId = currentPatientId
        getUserData(response.account.username).then(userData => {
            const user = userData
            if (user) {
                currentPatientPortalUser = user
                patientProfileId = user.patientProfileId
                sessionStorage.setItem("currentPatientPortalUser", JSON.stringify(user))
                sessionStorage.setItem("patientProfileId", user.patientProfileId)
                const ppId = user.patientProfileId
                getUserPIIData(ppId).then(piiResponse => {
                    currentPatientPortalPIIData = piiResponse.apiResponse.patientProfile
                    sessionStorage.setItem('currentPatientPortalPIIData', JSON.stringify(currentPatientPortalPIIData))
                    getUserGenericData(ppId).then(genericAnswers => {
                        currentPatientPortalGenericData = genericAnswers.apiResponse.genericHealthQuestionAnswers
                        sessionStorage.setItem('currentPatientPortalGenericData', JSON.stringify(currentPatientPortalGenericData))
                        updateScreenerAllPiiAndGenericHealthQuestionAnswers({
                            patientId,
                            patientProfileId: ppId
                        }).then(() => {
                            autoPopulateIndependentPIIQuestions(m_screen.m_question)
                            username = response.account.username;
                            showWelcomeMessage(response.account);
                            userEmail = response.username
                            sessionStorage.setItem('userEmail', response.username)
                            renderAuthBanner()
                            // location.reload();

                        })


                    })

                })
            }

        })
    } else {
        selectAccount();
    }
}

function signUp() {
    myMSALObj.loginPopup(signUpRequest).then(registerResponse).catch(error => console.error(error));
}
function signIn() {
    myMSALObj.loginPopup(loginRequest).then(loginResponse).catch(error => console.error(error));
}

function signOut() {
    const logoutRequest = {
        account: myMSALObj.getAccountByUsername(username),
        navigateToLogoutRequestUrl: false,
    };

    myMSALObj.logoutPopup(logoutRequest).then(() => {
        // Clean local variables
        currentPatientPortalUser = null;
        currentPatientPortalPIIData = null;
        currentPatientPortalGenericData = null;
        patientProfileId = null;
        // Clean Session variables
        sessionStorage.removeItem("currentPatientPortalUser")
        sessionStorage.removeItem("currentPatientPortalPIIData")
        sessionStorage.removeItem("currentPatientPortalGenericData")
        sessionStorage.removeItem("patientProfileId")
        renderAuthBanner()
    });
}

function getTokenPopup(request) {
    request.account = myMSALObj.getAccountByUsername(username);
    return myMSALObj.acquireTokenSilent(request)
        .catch(error => {
            console.warn("silent token acquisition fails. acquiring token using popup");
            if (error instanceof msal.InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return myMSALObj.acquireTokenPopup(request)
                    .then(tokenResponse => {
                        return tokenResponse;
                    }).catch(error => {
                        console.error(error);
                    });
            } else {
                console.warn(error);
            }
        });
}

function seeProfile() {
    getTokenPopup(loginRequest)
        .then(response => {
            callMSGraph(graphConfig.graphMeEndpoint, response.accessToken, updateUI);
        }).catch(error => {
            console.error(error);
        });
}

selectAccount();
